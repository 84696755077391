import React from 'react';
import Header from '../components/Header';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Layout/Seo';
import ServiceBanner from '../sections/Services/Banner';
import OurServices from '../sections/Services/Services';
import Footer from '../components/Footer';
// import SeeAlso from '../sections/Services/SeeAlso';
import ContactUs from '../sections/Services/ContactUs';

export default function ServicesPage({location}) {
  const { state = {} } = location;
  const { subItemId = ''} = state || {};

  return (
    <Layout  style={
      {
        backgroundColor: 'white'
      }
    } subItemId={subItemId}
    >
      <Seo title="Services" />
      <Header location={location} />
      <ServiceBanner />
      <OurServices id={'our-services'} />
      {/*<SeeAlso id={'see-also'} />*/}
      <ContactUs id={'lets-get-to-work'} />
      <Footer />
    </Layout>
  );
}
