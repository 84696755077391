import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  serviceContainer: {
    overflow: 'auto',
    width: '100%',
    padding: '0 20rem',
    maxHeight: 'max-content',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      overflow: 'auto',
      flexWrap: 'nowrap',
      width: '90%',
      margin: 'auto',
    },
  },
  serviceItem: {
    [theme.breakpoints.down('sm')]: {
      width: 'min-content',
    },
  }
}));
