import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles(theme => ({
  container: {
    // backgroundColor: theme.palette.secondary.light,
    // borderTop: `1px solid ${theme.palette.secondary.light}`,
    // TODO: remove when see also section will be active
    backgroundColor: theme.palette.secondary.main,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    // --- end ---
  },
  textContainer: {
    paddingTop: theme.spacing(4.5)
  },
  description: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  formContainer: {
    zIndex: 100,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  itemContainer: {
    padding: '0 18.5rem',
    paddingTop: theme.spacing(7),
    [theme.breakpoints.down('md')]: {
      padding: '0 4.5rem',
      paddingTop: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0 1.5rem',
      paddingTop: theme.spacing(5),
      height: '6rem'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 1.5rem',
      paddingTop: theme.spacing(5),
      height: '10rem'
    },
  }
}));
