import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomTitle } from '../../../components/CustomText';
import PuzzleCard from '../../../components/PuzzleCard';
import ServiceCard from '../../../components/ServiceCard';
import { servicesList } from '../../Home/Services/constants';
import { cardList } from './constants';
import { useStyles } from './styles';
import clsx from 'clsx';

export default function OurServices({id}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid id={id} container justify={'center'} className={classes.container}>
      <CustomTitle title={t('ourServices')} />
      <Grid item lg={6} md={8} sm={9} className={classes.textContainer}>
        <Typography variant={'body1'} align={'center'} className={classes.description}>
          {t('ourServicesDescription')}
        </Typography>
      </Grid>
      {servicesList(t).map((service, index) => {
        return (
          <Grid
            key={`service-card-${service.id}`}
            item xs={12}
            container
            className={clsx(classes.root, (index % 2 !== 0) && classes.reverse)}
          >
            <Grid item md={6} container justify={'center'} className={classes.imageCard}>
              <Grid item container xs={10}>
                <ServiceCard
                  service={service}
                  align={(index % 2 !== 0) ? 'flex-end' : 'flex-start'}
                  textAlign={(index % 2 !== 0) ? 'right' : 'left'}
                />
              </Grid>
            </Grid>
            <Grid item md={6} container spacing={2} className={classes.cardMenuContainer}>
              <PuzzleCard
                list={cardList(t)[index]}
                customClasses={clsx((index % 2 !== 0) ? classes.orangeCard : classes.blueCard)}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
