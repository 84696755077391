export const cardList = (t) => [
  [
    {
      id: 1,
      title: t('cardList11'),
    },
    {
      id: 2,
      title: t('cardList12'),
    },
    {
      id: 3,
      title: t('cardList13'),
    },
    {
      id: 4,
      title: '',
    }
  ],
  [
    {
      id: 1,
      title: t('cardList21'),
    },
    {
      id: 2,
      title: t('cardList22'),
    },
    {
      id: 3,
      title: '',
    },
    {
      id: 4,
      title: t('cardList23'),
    }
  ],
  [
    {
      id: 1,
      title: t('cardList31'),
    },
    {
      id: 2,
      title: t('cardList32'),
    },
    {
      id: 3,
      title: '',
    },
    {
      id: 4,
      title: '',
    }
  ],
  [
    {
      id: 1,
      title: t('cardList41'),
    },
    {
      id: 2,
      title: t('cardList42'),
    },
    {
      id: 3,
      title: '',
    },
    {
      id: 4,
      title: '',
    }
  ],
  [
    {
      id: 1,
      title: t('cardList51'),
    },
    {
      id: 2,
      title: t('cardList52'),
    },
    {
      id: 3,
      title: t('cardList53'),
    },
    {
      id: 4,
      title: '',
    }
  ]
];
