import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { CustomTitle } from '../../../components/CustomText';
import { useStyles } from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import Banner from '../../../components/Banner';
import ContactUsForm from '../../../components/ContactUs';
import { useTranslation } from 'react-i18next';


export default function ContactUs({id}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "letsWork.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);


  return (
    <div id={id} className={classes.container}>
    <Banner img={imageData.file.childImageSharp.fluid} interactive={false}>
      <div className={classes.itemContainer}>
      <CustomTitle title={t('letsGetToWork')} color={'secondary'} />
      <Typography variant={'body1'} color={'secondary'} gutterBottom className={classes.description}>
        {t('servicesContactUs')}
      </Typography>
      <Grid container className={classes.formContainer} spacing={1} justify={'center'}>
        <Grid item lg={9} md={10} xs={12}>
          <ContactUsForm text={t('contactUs')} />
        </Grid>
      </Grid>
      </div>
    </Banner>
</div>
  );
}
