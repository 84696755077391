import React from 'react';
import { useStyles } from './styles';
import { graphql, useStaticQuery } from 'gatsby';
import { servicesList } from './constants';
import Banner from '../../../components/Banner';
import { Grid } from '@material-ui/core';
import ServiceCard from '../../../components/ServiceCard';
import { useTranslation } from 'react-i18next';

export default function ServiceBanner() {
  const classes = useStyles();
  const {t} = useTranslation();

  const imageData = useStaticQuery(graphql`
  {
    file(relativePath: {eq: "servicesBG.png"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `);


  return (
    <Banner img={imageData.file.childImageSharp.fluid} header interactive={false}>
      <Grid container className={classes.serviceContainer} spacing={1} justify={'space-between'}>
        {servicesList(t).map(service => {
          return (
            <Grid item container key={service.id} sm={2} className={classes.serviceItem}>
              <ServiceCard service={service} showDescription={false} color={'secondary'} />
            </Grid>);
        })}
      </Grid>
    </Banner>
  );
};
